// == PWD WP Sass Framework ==
// Breadcrumbs navigation styles
// Template: templates/nav-breadcrumbs.php

@import "common/variables";
@import "common/tools";

.sub-pages {

}

.sub-pages a {
	margin-bottom: 20px;
	display: block;
	background-color: $theme-color-1;
	color: $white;
	border-radius: 0 16px 0 16px;
	overflow: hidden;
	min-height: calc(100% - 20px);

	h2 {
		text-align: center;
		color: $white;
		@include pwd-fontsize(18);
		font-weight: 500;
	}

	.image img {
		margin: 0;
	}

	.content {
		padding: 20px;
	}
}
